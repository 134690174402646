export const getTextWidth = ({ text, font }: { text: string; font?: string }) => {
  if (typeof document !== "undefined") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context?.font) {
      context.font = font || getComputedStyle(document.body).font;
      return context?.measureText(text).width;
    }
    return 0;
  }
  return 0;
};
