import { pressedEnter, Avatar, BodySSemiBold, BoxedButton, ButtonColorFamilyEnum, CaretDown, colors, SubOrg, Truncate, useMeasureWatchResize, User, UserCardModal, type UserCardOptionsType, UserCardOrganizationsType, Grid } from "@cerebruminc/cerebellum";
import { useAtom, useAtomValue } from "jotai";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";
import React, { type FC, type KeyboardEvent, useCallback, useEffect, useState, useMemo } from "react";
import { styled } from "styled-components";
import { currentOrgIdAtom, organizationsAtom, windowSizeAtom } from "src/atom";
import { allowedUserPaths, paths } from "src/const";
import { useUpdateSelectedOrganizationId, useWhoAmI } from "src/hooks";
import { UserControlsBase, AvatarBox, CaretBox } from "./UserControlsStyles";
import type { UserControlsType } from "./types";
import { hasSuperAdminRole } from "src/helpers";
import { useHandleLogout } from "src/hooks/useHandleLogout";
import { IconBox } from "src/styles/SharedStyles";
import { SIDEBAR_WIDTH } from "../Layouts/PageLayout/PageLayoutStyles";
import { getTextWidth } from "src/helpers/utils/getTextWidth";
const ALL_ORGS_OPTION_ID = "ALL_ORGS_OPTION_ID";
const ELEMENTS_GAP = 66,
  PADDING_SUM = 58,
  CARET_SIZE = 20,
  NAVBAR_PADDING = 40,
  TEXT_SPACE = 50;
const OrgHeaderLogo = styled.img`
  height: 16px;
  width: auto;
  display: inline-block;
  margin-right: 10px;
`;
const OrgNameBox = styled.div<{
  $isLargeText: boolean;
  $sizeAdjustment: number;
}>`
  display: inline-block;
  pointer-events: auto; 
  max-width: 600px;
  width: auto;
  ${({
  $isLargeText,
  $sizeAdjustment
}) => $isLargeText && `  @media all and (max-width: calc(600px + ${$sizeAdjustment}px)) {
    width: calc(100vw - ${$sizeAdjustment}px);
  }`}
`;
const OrgButtonLogo = styled.img`
  height: auto;
  width: 20px;
  display: block;
`;
const Name = styled(BodySSemiBold)`
  max-width: 400px;
  @media all and (max-width: 900px) {
    max-width: 300px;
  }
  @media all and (max-width: 800px) {
    max-width: 200px;
  }
`;
export const UserControls: FC<UserControlsType> = ({
  white
}) => {
  const {
    neuronWebProfileSettings
  } = useFlags();

  // UserCard
  const [showUserCard, setShowUserCard] = useState(false);
  const [avatarRef, avatarMeasure] = useMeasureWatchResize();
  const [usernameRef, usernameMeasure] = useMeasureWatchResize();
  const {
    width
  } = useAtomValue(windowSizeAtom);
  const modifiedAvatarMeasure = avatarMeasure;
  modifiedAvatarMeasure.left = width - 30;
  modifiedAvatarMeasure.right = width - 30;
  const {
    data: userData
  } = useWhoAmI();
  const {
    email,
    firstname,
    lastname
  } = userData || {};
  const organizations = useAtomValue(organizationsAtom);
  const {
    mutateAsync: updateSelectedOrganizationId
  } = useUpdateSelectedOrganizationId();
  const [currentOrgId, setCurrentOrgId] = useAtom(currentOrgIdAtom);
  const handleLogout = useHandleLogout();
  const router = useRouter();

  // If the user only has access to one organization, set that as the current org
  useEffect(() => {
    if (userData && organizations.length === 1) {
      setCurrentOrgId(organizations[0].id);
    }
  }, [userData, organizations, setCurrentOrgId]);
  const userName = `${firstname || ""} ${lastname || ""}`;
  const lastInitial = lastname?.charAt(0) || "";
  const shortUserName = `${firstname || ""} ${!firstname ? lastname || "" : lastInitial ? `${lastInitial}.` : ""}`;
  useEffect(() => {
    avatarMeasure.top = avatarMeasure.top + 20;
  }, [avatarMeasure]);
  const handleOrgChange = useCallback(async (orgId: string | null) => {
    setShowUserCard(false);
    setCurrentOrgId(orgId);
    if (userData?.id) {
      try {
        const response = await updateSelectedOrganizationId({
          id: userData.id,
          selectedOrganizationId: orgId
        });

        // Super admins get access to everything, but don't always have a currentRole listed
        const currentRole = response.roleAssignment?.find((item: any) => item.organizationId === orgId)?.roleV2?.name;
        const isSuperAdmin = hasSuperAdminRole(userData);
        const isBaseUser = (!currentRole || currentRole === "USER") && !isSuperAdmin;
        const restrictedRoute = !allowedUserPaths.has(router.pathname);
        const isAccountPath = router.pathname === `${paths.organizations}/[id]`;

        // redirect users to a usable path
        if (restrictedRoute && isBaseUser) {
          router.push(paths.home);
        } else if (isAccountPath) {
          // Reload the account page to display the new org
          router.push(`${paths.organizations}/${orgId}`);
        }
      } catch (error) {
        console.error("Failed to update selected organization id", error);
      }
    }
  }, [router, userData, updateSelectedOrganizationId, setCurrentOrgId]);
  const activeOrg = organizations?.find(org => org.id === currentOrgId);
  const activeOrgNameSize = useMemo(() => getTextWidth({
    text: activeOrg?.name || "",
    font: `15px "Nunito Sans", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`
  }), [activeOrg?.name]);
  const orgOptions: UserCardOrganizationsType[] = useMemo(() => {
    const options: UserCardOrganizationsType[] = organizations?.map(org => ({
      id: org.id,
      Icon: org.logoUrl ? () => <OrgButtonLogo src={org.logoUrl} alt={`${org.name} logo`} /> : undefined,
      text: org.name,
      onClick: () => {
        handleOrgChange(org.id);
      }
    })) || [];

    // Only show the "All Organizations" option if there are multiple organizations
    if (organizations && organizations.length > 1) {
      options.unshift({
        id: ALL_ORGS_OPTION_ID,
        Icon: () => <IconBox $size={16}>
            <Grid fill={colors.BLUE_100} />
          </IconBox>,
        text: "All Organizations",
        onClick: _item => handleOrgChange(null)
      });
    }
    return options;
  }, [organizations, handleOrgChange]);
  const userCardOptions: UserCardOptionsType[] = [{
    text: "Profile Settings",
    Icon: User,
    onClick: () => neuronWebProfileSettings ? router.push(paths.profileSettings) : router.push(paths.settings)
  }, {
    text: "Your Organizations",
    Icon: SubOrg,
    onClick: () => router.push(paths.organizations)
  }];
  const sizeAdjustment = SIDEBAR_WIDTH + usernameMeasure.width + avatarMeasure.width + ELEMENTS_GAP + PADDING_SUM + NAVBAR_PADDING + CARET_SIZE + TEXT_SPACE;
  return <>
      <UserControlsBase $white={white} onClick={() => setShowUserCard(true)} onKeyDown={(event: KeyboardEvent<HTMLSpanElement>) => {
      if (pressedEnter(event) || event.key === " ") {
        setShowUserCard(true);
        event.preventDefault(); // prevent scroll on spacebar
      }
    }} $clickable={!showUserCard} tabIndex={!showUserCard ? 0 : -1} role="button" aria-label="Open user settings panel">
        {!!userData && <div className="hidden sm:flex">
            <BoxedButton buttonHeight={30} clickable={false} colorFamily={ButtonColorFamilyEnum.CoolGrey} text={activeOrg ? <>
                    {activeOrg?.logoUrl && <OrgHeaderLogo src={activeOrg.logoUrl} alt={`${activeOrg.name} logo`} />}
                    <OrgNameBox $sizeAdjustment={sizeAdjustment} $isLargeText={activeOrgNameSize + sizeAdjustment >= width}>
                      <Truncate>{activeOrg?.name ?? " "}</Truncate>
                    </OrgNameBox>
                  </> : <span>All Organizations</span>} white={!white} />
          </div>}
        <AvatarBox ref={avatarRef} $white={white}>
          <Avatar placeholderInitials={userName} imageUrl={!userName && activeOrg?.logoUrl ? activeOrg.logoUrl : undefined} backgroundGradient={!userName && activeOrg?.logoUrl ? "transparent" : white ? undefined : "linear-gradient(180deg, rgba(255,255,255,0.78) 0%, rgba(255,255,255,0) 80%)"} themeOverride={{
          size: 50
        }} />
        </AvatarBox>
        {userData && <Name ref={usernameRef} $textColor={white ? colors.COOL_GREY_100 : colors.WHITE}>
            <Truncate>{shortUserName}</Truncate>
          </Name>}
        <CaretBox $white={white}>
          <CaretDown fill={colors.COOL_GREY_30} />
        </CaretBox>
      </UserControlsBase>

      <UserCardModal onClickout={() => setShowUserCard(false)} showUserCard={showUserCard} targetMeasure={modifiedAvatarMeasure} onSignOut={handleLogout} userEmail={email} userName={userName} topOffset={avatarMeasure.top + avatarMeasure.height} options={userCardOptions} organizations={orgOptions} activeOrg={activeOrg ? activeOrg?.id : ALL_ORGS_OPTION_ID} />
    </>;
};