import { ConfirmModal } from "@cerebruminc/cerebellum";
import Link from "next/link";
import React from "react";
interface SkipModalProps {
  onSkip: () => void;
  hideModal: () => void;
  modalVisible: boolean;
}
export function SkipModal({
  onSkip,
  hideModal,
  modalVisible
}: SkipModalProps) {
  return <ConfirmModal header="2-Factor Authentication has not been enabled." primaryButtonText="Ok" onPrimaryClick={onSkip} show={modalVisible} onXClick={hideModal} themeOverride={{
    modalWidth: 430,
    primaryButtonWidth: 140,
    modalPaddingSide: 70,
    modalPaddingBottom: 50,
    modalPaddingTop: 50
  }} />;
}