import { colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import { USER_CONTROLS_HEIGHT } from "src/const";
export const SIDEBAR_WIDTH = 198;
export const MainPageContainer = styled.div`
  background: ${colors.WHITE};
  max-height: 100vh;
`;
export const ContentContainer = styled.div`
  padding-left: ${SIDEBAR_WIDTH}px;
`;
export const ChildrenContainer = styled.div<{
  $addPadding?: boolean;
}>`
	height: calc(100% - ${USER_CONTROLS_HEIGHT}px);
  padding: ${({
  $addPadding
}) => $addPadding ? "20px 33px 30px 38px" : "0"};
`;
export const Sidebar = styled.div`
  position: fixed;
  width: ${SIDEBAR_WIDTH}px;
  top: 0;
  z-index: 11;
  section {
    max-height: 100vh;
    overflow: hidden;
  }
`;