import { styled } from "styled-components";
export const NudgeToggle = styled.span`
  display: block;
  margin-left: -13px;
`;
export const NameBase = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  padding: 8px 0;
`;
export const NamePlate = styled.div``;