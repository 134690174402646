import { colors } from "@cerebruminc/cerebellum";
import { styled } from "styled-components";
import type { IconBoxProps } from "./types";
export const IconBox = styled.div<IconBoxProps>`
  width: ${({
  $size
}) => $size || 20}px;
  height: ${({
  $size
}) => $size || 20}px;
`;
export const DownloadLink = styled.a`
  border-bottom: 1px solid transparent;
  border-radius: 3px;
  color: ${colors.BLUE_100};
  line-height: 15px;
  max-width: 100%;
  padding: 4px 6px 3px;
  position: relative;
  text-decoration: none;
  &:hover {
    color: ${colors.BLUE_120};
  }
  &:focus-visible {
    outline: none;
    border: 1px solid ${colors.BLUE_70};
  }
  &:active {
    top: 1px;
  }
`;
export const DownloadText = styled.span`
  display: inline-block;
  border-bottom: 1px solid ${colors.BLUE_100};
`;