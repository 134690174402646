import * as React from "react";
interface VidLogoCircleProps {
  size?: number;
}
const VidLogoCircle: React.FC<VidLogoCircleProps> = ({
  size = 80
}) => <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 88 88">
    <title>{"0DC05888-9FC7-4ACB-99D9-AB0696C6186F"}</title>
    <defs>
      <linearGradient id="a" x1="-3.612%" x2="103.977%" y1="3.483%" y2="96.834%">
        <stop offset=".42%" stopColor="#F9A18E" />
        <stop offset="50.18%" stopColor="#A48AFF" />
        <stop offset="100%" stopColor="#61E9FE" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="nonzero">
      <circle cx={44} cy={44} r={44} fill="url(#a)" />
      <g fill="#FFF">
        <path d="M47.432 31.082 30.835 53.38l-3.089 4.523h6.75v-.396l19.677-26.426zM43.102 31.055H36.38L20.196 52.571l-6.424-8.254h-6.75L17.415 57.93h5.535l.379-.501.193-.273 18.26-24.306zM74.765 34.98c-2.957-2.508-6.89-3.89-11.088-3.89l-4.858-.035-19.351 25.987v.827h.097l-.009.009h24.112c8.677 0 15.734-6.01 15.734-13.403 0-3.59-1.645-6.97-4.637-9.495Zm-.995 9.504c0 2.235-1.038 4.347-2.93 5.94-1.91 1.62-4.462 2.508-7.163 2.508l-14.256-.22 12.425-16.685h1.822c2.728 0 5.28.889 7.172 2.5 1.892 1.61 2.93 3.722 2.93 5.957Z" />
      </g>
    </g>
  </svg>;
export default VidLogoCircle;